export function dailyWordReturn(){
  const date = new Date();
  const numberDate = date.getDate();
  return dailyWordChosen(numberDate);
}


export function dailyWordChosen(numberDate){
  const dailyLetter = {
    '0': 'AEDGHC',
    '1': 'JKLUIE',
    '2': 'OUYLFM',
    '3': 'JVYSLI',
    '4': 'HCYWEW',
    '5': 'QPSIUN',
    '6': 'JCHSUA',
    '7': 'HCYWOP',
    '8': 'ZMLISJ',
    '9': 'PIOGLM',
    '10': 'UYRYNE',
    '11': 'MCVDKU',
    '12': 'BCNMSE',
    '13': 'GJSIJA',
    '14': 'PDOLUS',
    '15': 'WEULCI',
    '16': 'CMVDSU',
    '17': 'POXUNS',
    '18': 'ISULEM',
    '19': 'CNDUMA',
    '20': 'MXNSHU',
    '21': 'GIUSLA',
    '22': 'HEGXBL',
    '23': 'QUWEMN',
    '24': 'WOLERM',
    '25': 'OPYELN',
    '26': 'MEUSNR',
    '27': 'CYETSN',
    '28': 'VOLYPS',
    '29': 'AEDGHC',
    '30': 'JKLUIE',
    '31': 'OUYLFM',

  };
  if(dailyLetter[numberDate] == null){
    return 'XAIJKL';
  }
  
  return dailyLetter[numberDate];
}